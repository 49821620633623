import React, { Component } from "react";
import MyContext from "../context/myContext";
import Translation from "../../components/translation/translation";
import { logIn, changeLang } from "../../reducers/actions/index";
import { Link, navigate } from "gatsby";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { connect } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import "./login.scss";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';

class LoginPage extends Component {
  session
  constructor(props, context) {
    super(props, context)
    this.state = {
      client: this.context.sdkClient,
      showPassword: false,
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.langSaved) {
      this.props.changeLang(this.props.langSaved)
    }
  }

  render() {
    return (
      <div className="login_page">
        <div className="titles">
          <span
            className="back"
            onClick={() =>
              navigate("/how", {
                state: { optinAccepted: true, lang: this.props.lang },
              })
            }
          >
            <ArrowBackIosIcon />
            <Translation trl="back_how" />
          </span>
          <span
            className="back_mobile"
            onClick={() =>
              navigate("/how", {
                state: { optinAccepted: true, lang: this.props.lang },
              })
            }
          >
            <ArrowBackIosIcon />
            <Translation trl="back" />
          </span>
          <h1>
            <Translation trl="login_main_title" />
          </h1>
          <h2>
            <Translation trl="fill_form_login" />            
          </h2>
          <h4 className="msg-sub">
          <Translation trl="rajout_message_alert" />
        </h4>
        </div>
      
        <form>
          <div
            className={
              this.state.nissError
                ? "form_input error"
                : this.state.nissError === false
                ? "form_input success"
                : "form_input"
            }
          >
            <label>
              <Translation trl="niss" />
              <CheckCircleOutlineRoundedIcon />
            </label>
            <input
              value={this.state.idNumber}
              placeholder="XX.XX.XX-XXX.XX"
              onChange={e => this.changeNiss(e.target.value)}
              onBlur={e => this.checkNISS(e.target.value)}
            />
          </div>
          <div className="form_input">
            <label>
              <Translation trl="pwd" />
            </label>
            <input
              type={this.state.showPassword ? "text" : "password"}
              autoComplete="new-password"
              placeholder="**********"
              value={this.state.password}
              onChange={e => this.setState({ password: e.target.value })}
            />
            {!this.state.showPassword && (
              <VisibilityIcon
                className="pwd_icon"
                onClick={() => this.setState({ showPassword: true })}
              />
            )}
            {this.state.showPassword && (
              <VisibilityOffIcon
                className="pwd_icon"
                onClick={() => this.setState({ showPassword: false })}
              />
            )}
          </div>
          <Link to="/password" state={{ lang: this.props.lang }}>
            <label className="forgot">
              <Translation trl="pwd_forgot" />
            </label>
          </Link>
        </form>
        {this.state.loginError && (
          <div className="errors">
            <span>
              <Translation trl="loginError" />
            </span>
          </div>
        )}
        <div className="actions">
          <button
            className={this.isButtonValid() ? "primary" : "primary disabled"}
            onClick={e => this.onSubmit(e)}
          >
            <span>
              <Translation trl="login" />
            </span>
          </button>
        </div>
      </div>
    )
  }

  isButtonValid() {
    return this.state.idNumber && this.state.password
  }

  goBack() {
    if (this.isClientSide()) {
      localStorage.removeItem("qvax-selected-region")
      navigate("/region", { state: { lang: this.props.lang } })
    }
  }

  checkNISS(value) {
    if (value.length === 15) {
      const niss1 = +value
        .replace(/\./g, "")
        .replace("-", "")
        .substring(0, 9)
      const niss2 = +(
        "2" +
        value
          .replace(/\./g, "")
          .replace("-", "")
          .substring(0, 9)
      )
      const verif = +value.substring(13)
      if (97 - (niss1 % 97) === verif || 97 - (niss2 % 97) === verif) {
        this.setState({ nissError: false })
      } else {
        this.setState({ nissError: true })
      }
    } else {
      if (value.length > 0) {
        this.setState({ nissError: true })
      } else {
        this.setState({ nissError: undefined })
      }
    }
  }

  formatNiss(value) {
    const numbers = value.replace(/\D/g, "")
    const numb = numbers.split("")
    let inserted = 0
    numbers.split("").forEach((n, i) => {
      if (i === 2 || i === 4 || i === 9) {
        numb.splice(i + inserted, 0, ".")
        inserted += 1
      }
      if (i === 6) {
        numb.splice(i + inserted, 0, "-")
        inserted += 1
      }
    })
    return numb.join("")
  }
  changeNiss(value) {
    const lastChar = value[value.length - 1]
    const reg = /^\d+$/
    if (
      value.length <= 15 &&
      reg.test(lastChar) &&
      (!this.state.idNumber || value.length >= this.state.idNumber.length)
    ) {
      this.setState({ idNumber: this.formatNiss(value), nissError: undefined })
      if (value.length === 15) {
        this.checkNISS(value)
      }
    } else {
      if (value.length === 0) {
        this.setState({ idNumber: undefined })
      } else {
        if (this.state.idNumber && value.length < this.state.idNumber.length) {
          this.setState({ idNumber: value, nissError: undefined })
        } else {
          this.setState({ idNumber: this.state.idNumber })
        }
      }
    }
  }

  onSubmit(event) {
    event.preventDefault()
    this.state.client.sessionService
      .doEmailPasswordLogin(
        this.state.idNumber.replace(/\./g, "").replace("-", ""),
        this.state.password
      )
      .then(sess => {
        this.session = sess
        if (this.isClientSide()) {
          localStorage.setItem(
            "SeatersEmbeddedAuthToken",
            JSON.stringify(this.session)
          )
          this.setState({ loginError: false })
          navigate("/", { state: { lang: this.props.lang } })
        }
      })
      .catch(error => {
        this.setState({ loginError: true })
      })
    /* .then(fan => {
				this.props.onLogged(this.session, fan, this.state.client);
			}) */
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn)
    const dateNow = new Date()
    return expireDate > dateNow
  }

  isClientSide() {
    return typeof window !== "undefined"
  }
}

LoginPage.contextType = MyContext
const mapStateToProps = state => ({
  login: state.login,
  lang: state.lang,
})

const mapDispatchToProps = () => {
  return {
    logIn,
    changeLang,
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(LoginPage)
